import serviceBase from '@/services/serviceBase'
import { ApiBooleanResult, ApiResult } from '@/services/data'

export interface DualInvestmentRes {
  data: FigureRow[];
  dates: DateColumn[];
}

export interface DateColumn {
  Settlement: string;
  SettlementDisplay: string;
  SettlementInterval: number;
  Source: string;
}

export interface FigureRow {
  TargetPrice: number;
  Buffer: number;
  Rows: SourceFigureRow[];
}

export interface SourceFigureRow {
  Source: string;
  Settlement: string;
  Apy: number;
}

export default {
  async getFigureData (coin: string): Promise<ApiResult<DualInvestmentRes>> {
    return await serviceBase.tryPostResult<DualInvestmentRes>(`/DualInvestment/GetFigureData?coin=${coin}`)
  }
}
