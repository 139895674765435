














































import { Vue, Component } from 'vue-property-decorator'
import SearchFilter from '@/components/search-filter.vue'
import SortHeader from '@/components/SortHeader.vue'
import dualInvestmentService, {
  DateColumn,
  DualInvestmentRes,
  FigureRow,
  SourceFigureRow
} from '@/services/dualInvestmentService'
import utility from '@/common/utility'

@Component({ components: { SortHeader, SearchFilter } })
export default class Trades extends Vue {
  model: DualInvestmentRes = { data: [], dates: [] }
  loading = false
  coin = ''

  async mounted () {
    await this.load('BTC')
  }

  async load (coin: string) {
    this.model.data = []
    this.model.dates = []
    this.coin = coin
    this.loading = true
    const res = await dualInvestmentService.getFigureData(coin)
    utility.freezeArrayAndItems(res.Result.data)
    this.model = res.Result
    this.loading = false
  }

  readApy (Rows: SourceFigureRow[], dateColumn: DateColumn) {
    const find = Rows.find(p => p.Source === dateColumn.Source && p.Settlement === dateColumn.Settlement)
    return find ? (find.Apy * 100).toFixed(2) + '%' : ''
  }
}
